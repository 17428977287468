/* global $ SmoothlyMenu */

import classNames from 'classnames'
import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'

import { store } from '../../index'
import { request } from './utils'
import { load_choice } from '../choice/utils'


class SideMenu extends Component {

  constructor(props) {
    super(props)

    this.state = {
      opened_title: undefined,
    }
  }

  render() {
    if (this.props.items === undefined)
      return null

    function render_menu_item(item) {
      const icon_class = `menu-icon tf-icons ${item.icon}`

      // Ссылка
      if (item.url) {
        return <li className="menu-item" key={item.title}>
          <Link to={item.url} className="menu-link">
            <i className={icon_class}></i>
            <div>{item.title}</div>
          </Link>
        </li>
      }

      // Раздел с категориями
      if (item.items) {
        return (
          <li className="menu-item" key={item.title}>
            <a className="menu-link menu-toggle">
              <i className={item.icon}></i>
              <div data-i18n="Dashboards">{item.title}</div>
            </a>
            <ul className="menu-sub">
              {item.items.map((item) => { return render_menu_item(item) })}
            </ul>
          </li>
        )
      }
    }

    return (
      <aside className="layout-menu menu-vertical menu bg-menu-theme"
        onMouseEnter={() => { $("html").addClass("layout-menu-hover") }}
        onMouseLeave={() => { $("html").removeClass("layout-menu-hover") }}>
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              <i className="ti ti-rocket"></i>
            </span>
            <span className="app-brand-text demo menu-text fw-bold">SphereBPMS</span>
          </Link>
          <a className="layout-menu-toggle menu-link text-large ms-auto">
            <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" onClick={() => {
              $("html").toggleClass("layout-menu-collapsed")
            }}></i>

            <i className="ti ti-x d-block d-xl-none ti-sm align-middle"
              onClick={() => { $("html").removeClass("layout-menu-expanded") }}></i>
          </a>
        </div>
        <div className="menu-inner-shadow"></div>
        <ul className="menu-inner py-1">
          {this.props.items.map((item) => { return render_menu_item(item) })}
        </ul>
      </aside>
    )
  }
}

export class BaseView extends Component {
  constructor(props) {
    super(props)

    this.auth_required = true
    this.logout = this.logout.bind(this)
    this.redirect_to = this.redirect_to.bind(this)
    this.getChoice = this.getChoice.bind(this)
    this.waitRenderReady = this.waitRenderReady.bind(this)
    this.renderTopPanelRightItems = this.renderTopPanelRightItems.bind(this)
    this.renderTopPanelLeftItems = this.renderTopPanelLeftItems.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // При установки редиректа, очищаем его. В методе render Navigate вернет редирект,
    // но нам нужно подчистить состояние если редирект придет на это жу представление.
    if (prevState && prevState.redirect_to === undefined && this.state.redirect_to !== undefined) {
      this.setState({ redirect_to: undefined })
    }
  }

  // Пользователь авторизован
  get authorized() {
    return this.props.authorized
  }

  get user() {
    return this.props.user
  }

  get user_title() {
    return this.user ? this.user.title : ''
  }

  redirect_to(path) {
    this.setState({ redirect_to: path })
  }

  logout() {
    localStorage.removeItem('auth_access_token')
    localStorage.removeItem('auth_refresh_token')
    localStorage.removeItem('auth_refresh_url')

    store.dispatch({ type: 'auth_clean', data: null })
  }

  // Преобразовать значение из справочника для вывода на экран
  getChoice(choice_name, value) {
    const items = this.props.choices[choice_name]
    if (items === undefined) {
      load_choice(choice_name)
      return null
    }

    let verbose_value;
    items.forEach(element => {
      if (element[0] === value) {
        verbose_value = element[1]
      }
    });

    return verbose_value
  }

  renderSidePanel() {
    return <SideMenu items={this.props.panel_items} />
  }

  renderTopPanelLeftItems() {
    return null
    //<ul className="nav navbar-top-links navbar-left pull-left">
    //</ul>
  }

  renderTopPanelRightItems() {
    if(this.authorized) {
      return (
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a className="dropdown-toggle" data-bs-toggle="dropdown" data-trigger="hover">
              <i className="ti ti-user me-2"></i>
              {this.user_title}
            </a>
            <div className="dropdown-menu dropdown-menu-end mt-2">
              <Link to="/auth/change_password/" className="dropdown-item">
                <i className="ti ti-key me-2 ti-sm" /> Поменять пароль
              </Link>
              <a onClick={this.logout} className="dropdown-item">
                <i className="ti ti-logout me-2 ti-sm" /> Выход
              </a>
            </div>
          </li>
        </ul>
      )
    }

    return <span >
      <Link to="/auth/login" className="nav-link">
        <i className="ti ti-login rounded-circle me-1"/>
        <span className="align-middle">Вход</span>
      </Link>
    </span>
  }

  renderTopPanel() {
    return (
      <div className="container-fluid my-2">
        <div className="card">
          <div className="card-body d-flex p-3">
            <div className="flex-grow-1">

              {this.props.panel_items !== undefined && <span className="me-2 d-xl-none">
                <a onClick={() => { $("html").addClass("layout-menu-expanded") }}>
                  <i className="ti ti-menu-2 ti-sm"></i>
                </a>
              </span>}

              {this.renderTopPanelLeftItems()}
            </div>
            <div className="flex-grow-2">{this.renderTopPanelRightItems()}</div>
          </div>
        </div>
      </div>
    )
  }

  renderBreadcrumbs() {
    return null
    // return (
    //     <nav aria-label="breadcrumb">
    //         <ol className="breadcrumb breadcrumb-style1">
    //             <li className="breadcrumb-item">
    //                 <a >Домой</a>
    //             </li>
    //             <li className="breadcrumb-item active">Домой</li>
    //         </ol>
    //     </nav>
    // )
  }

  renderContent() {
    return null
  }

  renderFooter() {
    return (
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-fluid">
          <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
            <div></div>
            <div className="d-none d-lg-inline-block">
              ❤️ <a href="http://ssphera.ru/" className="footer-link text-primary fw-medium" target="_blank">СтратоСфера</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  renderModals() { }

  /**
   * Ждем готовности данных к рендеру страницы.
   * Пока данные не пришли, возвращаем какой-то контент-заглушку.
   *
   * когда оно начинает выдавать undefined, значит все нужные для рендера данные пришли
   * и можно рендерить
   */
  waitRenderReady() {
    const that = this

    // Если находимся в ожидании данных, дальше не идем
    if(this.waitRenderReadyLock) {
      return <div>Ожидание загрузки данных</div>
    }

    // Требуется авторизация, а пользователь не авторизован
    if (this.auth_required && !this.authorized) {
      return <Navigate to="/" replace={true} />
    }

    if (this.state && this.state.redirect_to !== undefined) {
      return <Navigate to={this.state.redirect_to} replace={true} />
    }

    // Арторизован, но данные пользователя пока не доехали - ждем
    if (this.authorized && !this.user) {
      that.waitRenderReadyLock = true
      request({
        method: 'get',
        url: '/api/auth/me',
        success: (data) => {
          that.waitRenderReadyLock = false
          store.dispatch({ type: 'auth_data', data })
        },
      })

      return <div>Загрузка данных пользователя...</div>
    }

    // Ждем пока придет меню (если пользователь авторизован)
    if (this.authorized && this.props.panel_items === undefined) {
      that.waitRenderReadyLock = true
      request({
        method: 'get',
        url: '/api/lib/menu',
        success: (data) => {
          that.waitRenderReadyLock = false
          store.dispatch({ type: 'lib_panel_items', data })
        },
      })

      return <div>Загрузка навигационной панели...</div>
    }
  }

  render() {
    // Дождемся готовности всех данных для рендера страницы
    const dummyContent = this.waitRenderReady()
    if(dummyContent !== undefined) {
      return dummyContent
    }

    // Если нет меню, то рендерим без него
    const wrapper_class = classNames('layout-wrapper layout-content-navbar', {
      "layout-without-menu": this.props.panel_items === undefined,
    })

    return (
      <div className={wrapper_class}>
        <div className="layout-container">
          {this.renderSidePanel()}
          <div className="layout-page">
            {this.renderTopPanel()}
            <div className="content-wrapper">
              <div className="container-fluid flex-grow-1 container-p-y pt-2">
                {this.renderBreadcrumbs()}
                {this.renderContent()}
              </div>
              {this.renderFooter()}
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
        {this.renderModals()}
      </div>
    )
  }
}

export function baseStateToProps(state) {
  return {
    authorized: state.auth.authorized,
    user: state.auth.user,
    panel_items: state.lib.panel_items,
    choices: state.choice.choices,
  }
}
